/* Login.module.css */
.login {
  @apply flex flex-col items-center justify-center h-[52.1rem] bg-white;
}

.login__logo {
  @apply w-[200px] bg-black mb-8 p-2.5 rounded-[10px];
}

.login__container {
  /* @apply bg-white; */
  @apply flex flex-col items-center bg-white  w-[30%] p-2 rounded-lg;
}

.login__container h1 {
  @apply mr-20 text-start text-[2rem] text-[#333];
}

.subTitle {
  @apply self-start text-start mr-14;
}

.login__container form {
  @apply flex flex-col w-full;
}

.login__container h5 {
  @apply font-semibold mb-2 text-[1.1rem] text-[#555];
}

.login__container input {
  @apply mb-6 w-full h-[2rem] pt-[0.8rem] pb-[0.8rem] pl-2.5 border border-solid border-[#ccc] rounded-[0.3rem] text-[1rem] focus:outline-none;
}

.login__signInButton {
  @apply cursor-pointer font-semibold mb-6 w-full p-[0.8rem] text-white border-none rounded-[0.3rem] text-[1.1rem] transition-[background-color] duration-[0.3s] ease-[ease];
  background-image: 
    radial-gradient(circle at 100% 100%, rgb(21, 74, 107) 0%, rgba(21, 74, 107, 0) 40%),
    radial-gradient(circle at 0% 0%, rgba(45, 33, 88, 1) 0%, rgba(30, 41, 59, 0) 40%),
    linear-gradient(90deg, rgba(13, 14, 56, 1) 0%, rgba(13, 14, 56, 1) 100%),
    url("data:image/svg+xml,%3Csvg viewBox='0 0 251 251' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noiseFilter'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='5.29' numOctaves='3' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%25' height='100%25' filter='url(%23noiseFilter)'/%3E%3C/svg%3E");
  background-size: 100% 100%, 100% 100%, 100% 100%, 251px 251px;
  background-position: center, center, center, center;
  background-repeat: no-repeat, no-repeat, no-repeat, repeat;
}

.login__signInButton:hover {
  @apply bg-[#0056b3];
}

.midOption {
  @apply flex flex-col items-center justify-center w-full md:font-[Roboto] mb-2 text-[#555];
}

.login__thirdIdentityContainer {
  @apply flex flex-row items-center justify-center gap-2 mb-5;
}

.login__thirdIdentityButton {
  @apply flex items-center justify-center *:bg-white border border-gray-300  w-12 h-12 py-2 text-sm font-medium text-gray-800 hover:bg-gray-100;
  /* @apply focus:outline-none focus:ring-2 focus:ring-offset-2; */
}

.login__container p {
  @apply mb-6 text-[0.9rem] text-[#555] max-h-[20%];
}

.login__registerButton {
  @apply cursor-pointer font-semibold w-full p-[0.8rem] bg-[#28a745] text-white border-none rounded-[0.3rem] text-[1.1rem] transition-[background-color] duration-[0.3s] ease-[ease];
}

.login__registerButton:hover {
  @apply bg-[#1e7e34];
}

.login__container article {
 @apply text-[#555];
}

.signupLink {
  @apply text-[blue];
}

.footer {
  @apply flex flex-col text-start items-start justify-center mt-[4rem];
}

@media (max-width: 800px){
  .login__container {
    @apply w-[80%];
  }
}